@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

#about {
  background: #000;
  padding: 50px 0 50px 0;
  position: relative;
  margin-top: 6%;
  margin-bottom: 2%;
}

.techx-overview-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
}

.counters-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);  
  grid-gap: 20px;
}

.counter-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.counters {
  font-size: 16px;
  text-align: center;

  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
  @media screen and (max-width: 768px) {
    font-size: 8px;
  }
}

@media only screen and (max-width: 600px) {
  #techx-overview-section {
    margin: 20px auto 20px auto;
  }
  .techx-overview-text {
    text-align: justify;
    font-size: 0.9rem;
  }

  .counters {
    font-size: 0.9rem;
  }
}
