/* Badge.css */

.badge-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100vw;
  }
  
  
  .badge-container {
    margin: 50px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5%;
    width: 100vw;

    @media screen and (min-width: 768px) {
      min-height: 30vh;
    
  }
  }
  
  .badge-canvas-container {
    border: 2px solid transparent; /* Initially transparent */
    border-radius: 12px; /* Smooth rounded corners */
    background-image: linear-gradient(#f9f9f9, #f9f9f9), 
                      linear-gradient(45deg, #6a11cb, #2575fc); /* Inner background with outer gradient border */
    background-origin: border-box; /* Ensures the background color stays inside the border */
    background-clip: padding-box, border-box; /* Separates background and border */
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.badge-canvas-container:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
  
  .badge-canvas {
    display: block;
    max-height: 500px;
  }
  
  .badge-controls {
    width: 30vw;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .techx-overview-text {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
  }
  
  .input-name, .download-button {
    padding: 12px 20px;
    font-size: 1.2rem;
    width: 100%;
    max-width: 320px;
    border-radius: 6px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.input-name {
    border: 2px solid #ccc;
    color: #000;
    margin-bottom: 15px;
    outline: none;
}

.input-name:focus {
    border-color: #4caf50;
    box-shadow: 0 4px 8px rgba(76, 175, 80, 0.2);
}

.download-button {
    background-color: #89429f;
    color: white;
    border: none;
    cursor: pointer;
}

.download-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.download-button:not(:disabled):hover {
    background-color: #bc88cc;
    box-shadow: 0 4px 8px rgba(69, 160, 73, 0.2);
}

.download-button:active {
    background-color: #482b5b;
    transform: translateY(2px);
}

.socialmedia-link-badgepage {
  display: flex;
  justify-content: center;
  gap: 20px;
  list-style: none;
  padding: 0;
}

.socialmedia-link-badgepage li {
  transition: transform 0.3s ease;
}

.socialmedia-link-badgepage li:hover {
  transform: scale(1.2);
}

.socialmedia-link-badgepage svg {
  fill: #eee;
  width: 28px;
  height: 28px;
  transition: fill 0.3s ease;
}

.socialmedia-link-badgepage li:hover svg {
  fill: #b619d2; /* Change the color on hover */
}
