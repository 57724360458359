.ProjectCard {
  width: 352px;
  min-height: 435px; /* If this is causing extra space, consider changing to height */
  height: 435px; /* Set a specific height if appropriate */
  border-radius: 0.5rem;
  overflow: hidden;
  transition: transform 0.3s;
  cursor: pointer;
  box-shadow: rgb(20, 96, 228) 0px 4px 10px;
  display: flex; /* Ensure the card is a flex container */
  flex-direction: column; /* Align items vertically */
  justify-content: space-between; /* Distribute space between children */
}

.ProjectCard:hover {
  transform: scale(1.05);
  box-shadow: rgb(62, 186, 236) 0px 4px 10px;
}

.pdf-icon {
  background-color: transparent; 
  transition: background-color 0.3s ease;
}

.pdf-icon:hover {
  background-color: #e0f4ff;
}
.pdf-number{
  color:white;
}
.pdf-icon:hover .pdf-number {
  color: black;
}

@media screen and (min-width: 640px) {
  .ProjectCard {
    max-width: 506px;
  }
}

@media screen and (min-width: 768px) {
  .ProjectCard { /* Corrected class name from PortfolioCard to ProjectCard */
    max-width: 680px;
  }
}
